@use '../variables' as vars;
/**
 * This SCSS file defines a grid gap utility class system for responsive layout.
 *
 * The `$breakpoints` map defines the breakpoints for different screen sizes, which are used to generate responsive gap classes.
 *
 * The `$gap-values` map is generated dynamically, creating gap values from 0 to 30 pixels in increments of 6 pixels.
 *
 * The generated CSS classes follow the pattern:
 * - `.gap-{value}`: Sets the gap to the specified value for all screen sizes.
 * - `.{breakpoint}\\:gap-{value}`: Sets the gap to the specified value for the given breakpoint and larger.
 *
 * This utility system allows for easy and consistent control of grid gaps in your application.
 */
$breakpoints: (
  "xl": vars.$bp-xl,
  "ld": vars.$bp-ld,
  "md": vars.$bp-md,
  "sm": vars.$bp-sm,
  "xs": vars.$bp-xs,
);

$gap-values: ();

@for $i from 0 through 30 {
  @if $i % 6 == 0 {
    $gap-values: map-merge($gap-values, ("#{$i}" : #{$i}px));
  }
}

@each $key, $value in $gap-values {
  .gap-#{$key} {
    gap: #{$value};
  }
}

@each $breakpoint, $width in $breakpoints {
  @media (max-width: ($width - 1px)) {
    @each $key, $value in $gap-values {
      .#{$breakpoint}\:gap-#{$key} {
        gap: #{$value};
      }
    }
  }
}
