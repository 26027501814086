@use '../variables' as vars;

/**
 * This SCSS file defines a set of utility classes for applying padding to elements.
 *
 * The file defines a set of breakpoints and a map of padding values that are used to generate the utility classes.
 *
 * The utility classes are generated using Sass loops and have the following naming convention:
 *
 * - `.p-{value}`: Applies the specified padding value to all sides of the element.
 * - `.pt-{value}`, `.pr-{value}`, `.pb-{value}`, `.pl-{value}`: Applies the specified padding value to the top, right, bottom, or left side of the element, respectively.
 * - `.px-{value}`: Applies the specified padding value to the left and right sides of the element.
 * - `.py-{value}`: Applies the specified padding value to the top and bottom sides of the element.
 *
 * The utility classes are also generated for each breakpoint, with the breakpoint name prefixed to the class name (e.g. `.xs:p-4`).
 */
 $breakpoints: (
  "xl": vars.$bp-xl,
  "ld": vars.$bp-ld,
  "md": vars.$bp-md,
  "sm": vars.$bp-sm,
  "xs": vars.$bp-xs,
);

$padding-values: ();

@for $i from 0 through 16 {
  @if $i % 2 == 0 {
    $padding-values: map-merge($padding-values, ("#{$i}" : #{$i}px));
  }
}

@for $i from 20 through 36 {
  @if $i % 4 == 0 {
    $padding-values: map-merge($padding-values, ("#{$i}" : #{$i}px));
  }
}

@for $i from 50 through 80 {
  @if $i % 10 == 0 {
    $padding-values: map-merge($padding-values, ("#{$i}" : #{$i}px));
  }
}

@each $key, $value in $padding-values {
  .p-#{$key} {
    padding: #{$value};
  }
}

@each $key, $value in $padding-values {
  .pt-#{$key} {
    padding-top: #{$value};
  }
  .pr-#{$key} {
    padding-right: #{$value};
  }
  .pb-#{$key} {
    padding-bottom: #{$value};
  }
  .pl-#{$key} {
    padding-left: #{$value};
  }
  .px-#{$key} {
    padding-left: #{$value};
    padding-right: #{$value};
  }
  .py-#{$key} {
    padding-top: #{$value};
    padding-bottom: #{$value};
  }
}

@each $breakpoint, $width in $breakpoints {
  @media (max-width: ($width - 1px)) {
    @each $key, $value in $padding-values {
      .#{$breakpoint}\:p-#{$key} {
        padding: #{$value};
      }
      .#{$breakpoint}\:pt-#{$key} {
        padding-top: #{$value};
      }
      .#{$breakpoint}\:pr-#{$key} {
        padding-right: #{$value};
      }
      .#{$breakpoint}\:pb-#{$key} {
        padding-bottom: #{$value};
      }
      .#{$breakpoint}\:pl-#{$key} {
        padding-left: #{$value};
      }
      .#{$breakpoint}\:px-#{$key} {
        padding-left: #{$value};
        padding-right: #{$value};
      }
      .#{$breakpoint}\:py-#{$key} {
        padding-top: #{$value};
        padding-bottom: #{$value};
      }
    }
  }
}
