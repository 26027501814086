
$order-range: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;
@each $i in $order-range {
  .order-#{$i} {
    order: #{$i};
  }
}

.order-first { order: -9999; }
.order-last { order: 9999; }
.order-none { order: 0; }
