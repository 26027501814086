
/**
 * Utility classes for controlling the text transformation of an element.
 *
 * - `.uppercase`: Transforms the text to uppercase.
 * - `.lowercase`: Transforms the text to lowercase.
 * - `.capitalize`: Capitalizes the first letter of each word.
 * - `.normal-case`: Removes any text transformation, returning the text to its normal case.
 */
.uppercase { text-transform: uppercase; }
.lowercase { text-transform: lowercase; }
.capitalize { text-transform: capitalize; }
.normal-case { text-transform: none; }
