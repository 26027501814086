@use '../variables' as vars;
/**
 * Utility CSS classes for setting the maximum width of an element based on common screen sizes.
 * The max-width values are defined in the `$bp-*` Sass variables.
 */
.max-w-screen-xs {	max-width: vars.$bp-xs; }
.max-w-screen-sm {	max-width: vars.$bp-sm; }
.max-w-screen-md {	max-width: vars.$bp-md; }
.max-w-screen-ld {	max-width: vars.$bp-ld; }
.max-w-screen-xl {	max-width: vars.$bp-xl; }
